.skills__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.skills__list {
  max-width: 450px;
  min-height: 250px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.skills__list-item {
  margin: 0.5em;
  max-height: 32px;
}
