:root {
  --tw-space-x-reverse: 2;
}
.about {
  flex-direction: column;
  margin-top: 3em;
}
.about__legend {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.about__legend img {
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  border-radius: 100%;
  border: 4px solid var(--clr-primary);
  width: 15rem;
  height: 15rem;
}
/*
.about__legend img:hover {
  opacity: 0.8;
  transform: scale(90%);
}
*/
.about__name {
  color: var(--clr-primary);
}
.about__role {
  font-size: 2rem;
}
.about__role span {
  color: var(--clr-primary);
}
.about__desc {
  font-size: 1rem;
  max-width: 600px;
}

.about__desc,
.about__contact {
  margin-top: 2em;
}

.about .link--icon {
  margin-right: 0.5em;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
  }
  .app .about__legend img {
    width: 5rem;
    height: 5rem;
  }
}
